<template>
    <template v-for="(item, index) in modulesWithComponent" :key="`${index}-${item.config.component}`">
        <KeepAlive>
            <component
                v-if="moduleVisibility[index]"
                :is="item.component"
                :config="item.config"
                :data-index="index"
                :data-cy="`module-component-${item.config.component}`"
                :header-menu="headerMenu"
                :filter="props.nestedFilter ? props.filter : null"
            />
        </KeepAlive>
    </template>
    <template v-if="isEmpty">
        <slot name="empty" />
    </template>
</template>

<script setup>
    const props = defineProps({
        modules: Array,
        filter: Function,
        nestedFilter: Boolean,
        headerMenu: HTMLDivElement,
        paginationUrl: String,
    })
    const moduleHelpers = useModuleHelpers()

    const modulesWithComponent = computed(() => {
        return map(props.modules, (module) => ({
            component: module.component,
            config: module,
        }))
    })

    const moduleVisibility = computed(() => {
        return map(props.modules, (module) => {
            if (!props.filter) {
                return true
            }
            if (module.component === 'GroupListItem') {
                return false
            }
            return props.filter(module)
        })
    })

    const isEmpty = computed(() => {
        return every(moduleVisibility.value, (visible) => !visible)
    })
</script>
