<template>
    <IonPage>
        <IonContent>
            <div class="py-20 px-12 flex items-center space-x-2 w-full h-full">
                <div v-if="error" class="w-full">
                    <Empty icon="error">
                        <template v-slot:icon>
                            <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.6903 49.5716C39.2042 49.5716 53.4688 35.0002 48.4688 18.5002L24.906 18.5002C11.3921 18.5002 2.01158 18.3634 1.99996 18.5004C0.303396 38.501 12.1764 49.5716 25.6903 49.5716Z" fill="currentColor" fill-opacity="0.1" />
                                <path d="M25.5 49.4287C38.7548 49.4287 49.5 38.6835 49.5 25.4287C49.5 12.1739 38.7548 1.42871 25.5 1.42871C12.2452 1.42871 1.5 12.1739 1.5 25.4287C1.5 38.6835 12.2452 49.4287 25.5 49.4287Z" stroke="currentColor" stroke-opacity="0.7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.5 11.7148V25.7148" stroke="currentColor" stroke-opacity="0.7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.5 34.9999C26.4468 34.9999 27.2143 34.2323 27.2143 33.2856C27.2143 32.3388 26.4468 31.5713 25.5 31.5713C24.5532 31.5713 23.7857 32.3388 23.7857 33.2856C23.7857 34.2323 24.5532 34.9999 25.5 34.9999Z" stroke="currentColor" stroke-opacity="0.7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </template>

                        <template v-slot:title>
                            Oops, something went wrong.
                        </template>

                        <template v-slot:subtitle>
                            Please check your internet connection or try again soon.
                        </template>
                    </Empty>

                    <div v-if="showButtons" class="flex flex-col items-center -mt-10">
                        <UIButton class="mb-6" @click="retry">Try again</UIButton>
                        <a href="" class="underline text-sm" @click="reset">Reset app</a>
                    </div>
                </div>
                <div v-else-if="loading" class="absolute inset-0 flex items-center justify-center" data-cy="launch-loading">
                    <div class="flex flex-col items-center justify-center">
                        <UILoading class="w-20 h-20 !fill-blue-700 !text-blue-700/30" />
                        <div v-if="status" class="h-0">
                            <div class="mt-2 text-gray-300 text-sm">{{ status }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="error" class="absolute bottom-0 inset-x-0 text-gray-600 text-xs p-2 text-center">
                {{ error }}
            </div>
        </IonContent>
    </IonPage>
</template>

<script setup>
    import semver from 'semver'

    import { Capacitor } from '@capacitor/core'
    import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update'
    import { Colors } from '@digistorm/spark'
    import { App } from '@capacitor/app'
    import { Dialog } from '@capacitor/dialog'
    import { CapacitorUpdater } from '@capgo/capacitor-updater'
    import * as SentryVue from '@sentry/vue'

    import {
        api, analytics, preferences, mock,
    } from '@/services'
    import { AppIdentity } from '@/plugins/app-identity'
    import { biometric } from '@/services/biometric'

    const appStore = useAppStore()
    const authStore = useAuthStore()
    const badgeStore = useBadgeStore()
    const dashboardStore = useDashboardStore()
    const deviceStore = useDeviceStore()
    const moduleStore = useModuleStore()
    const moreStore = useMoreStore()
    const notificationsStore = useNotificationsStore()
    const versionStore = useVersionStore()

    const authHelpers = useAuthHelpers()
    const moduleHelpers = useModuleHelpers()
    const pushNotifications = usePushNotifications()
    const { launchAnimation } = useAnimations()
    const { snackbar } = useSnackbars()

    const router = useIonRouter()
    const { appVersion } = storeToRefs(appStore)
    const { version } = storeToRefs(versionStore)

    const status = ref(null)
    const error = ref(null)
    const showButtons = ref(true)
    const loading = ref(true)
    const biometricListener = ref(null)
    const checkedStoreAt = ref(null)

    let newAppVersion = {}

    const hotReloadDisabled = import.meta.env.VITE_HOT_RELOAD_DISABLED

    const doesHotCodeUpdates = computed(() => {
        return (Capacitor.isNativePlatform() || window.Cypress)
            && (!hotReloadDisabled || hotReloadDisabled === 'false')
    })

    const checkLegacyStorage = async () => {
        // Skip if already migrated
        if (await preferences.getGlobalItem('legacy_migrated') === 'true') {
            return
        }

        // Get device ids from legacy storage
        // Note: The legacy app did not store the reach device id
        await preferences.useLegacyStorage(true)

        const payload = {}
        try {
            const notificationManagerDevice = await preferences.getGlobalItem('school-app-notificationManagerDevice')
            payload.notification_manager_id = JSON.parse(notificationManagerDevice)?.id
        } catch (e) {
            console.error('Error parsing notificationManagerDevice', e)
        }
        try {
            const appControlDevice = await preferences.getGlobalItem('school-app-app-control')
            payload.app_control_id = JSON.parse(appControlDevice)?.device_id
        } catch (e) {
            console.error('Error parsing appControlDevice', e)
        }

        await preferences.useLegacyStorage(false)

        // Send the data to api
        try {
            await authStore.migrate(payload)

            // Set the flag to prevent re-migration
            await preferences.setGlobalItem({ key: 'legacy_migrated', value: 'true' })
        } catch (e) {
            console.error('Error migrating legacy storage', e)
        }
    }

    const checkBiometry = async () => {
        if (!version.value.biometrics_enabled) {
            return
        }

        const biometryEnabled = await biometric.isEnabled()
        console.log('Biometrics enabled is:', biometryEnabled)
        if (biometryEnabled) {
            try {
                await biometric.authenticate()

                // success
            } catch (error) {
                // something went wrong with biometrics
                throw new Error('Issue authenticating biometrics')
            }
        }
    }

    const viewNotification = (notification, replace = false) => {
        authStore.pushFormat(notification)
            .then((link) => {
                moduleHelpers.openModuleOrOpenBrowserForLinkData(link, replace)
                notificationsStore.fetch(true)
            })
            .catch((e) => {
                if (authHelpers.isAuthenticationException(e)) {
                    authHelpers.handleAuthenticationException(
                        e.response.data,
                        router,
                        (payload) => {
                            // Try again now that we're logged in, replace the view this time as the login view is still loaded
                            viewNotification(notification, true)
                        },
                        () => {
                            // Return to previous
                            if (router.canGoBack()) {
                                router.back()
                                return
                            }
                            router.replace({ name: 'dashboard' })
                        },
                        true,
                    )

                    // Prevent error from appearing while the login view launches
                    return
                }
                console.error('error', e)
                snackbar({
                    message: 'Error loading notification details',
                    type: 'error',
                })
            })
    }

    const handleNotification = (notification) => {
        Dialog.confirm({
            title: notification.title,
            message: notification.body,
            okButtonTitle: 'View',
            cancelButtonTitle: 'Dismiss',
        })
            .then(({ value }) => {
                notificationsStore.removeAllDeliveredNotifications()
                if (!value) {
                    return
                }
                viewNotification(notification)
            })
    }

    const addMockDataListeners = () => {
        appStore.events.on('mockDataEnabled', () => {
            badgeStore.refreshAuthFailures()
            moduleHelpers.navigateBackToDashboard()
            moduleStore.reset()
            dashboardStore.load()
            moreStore.load()
        })
    }

    const addPushListeners = () => {
        pushNotifications.events.on('pushNotificationActionPerformed', (action) => {
            handleNotification(action.notification)
        })

        pushNotifications.events.on('pushNotificationReceived', (notification) => {
            handleNotification(notification)
        })

        // Add native event listeners AFTER our event listeners are registered
        // The native event listeners will be triggered immediately by capacitor when registered,
        // so we need to ensure our listeners are added first
        pushNotifications.addListeners()
    }

    const loadDashboard = () => {
        return dashboardStore.load()
            .then(async () => {
                // Load the mock module if there is one
                if (mock.mockingModule()) {
                    moduleStore.addModule({
                        id: 'mock-module',
                        data: mock.content(),
                    })
                    router.replace({ name: 'view', params: { id: 'mock-module' } })
                    return
                }

                if (version.value.notifications_enabled) {
                    // Add push listeners once the dashboard is loaded to ensure app updates have been performed
                    // before showing any push notification popups
                    addPushListeners()

                    // Register push notifications once the dashboard has loaded
                    // This ensures the user has made it past auth on startup
                    if (!appStore.mockData) {
                        await pushNotifications.register()
                    }
                }

                if (version.value.biometrics_enabled && await biometric.canRequestBiometry()) {
                    await biometric.setPreviouslyRequested()
                    const biometryType = await biometric.biometryType()
                    Dialog.confirm({
                        title: `Enable ${ biometryType }?`,
                        message: `Would you like to enable ${ biometryType }?`,
                        okButtonTitle: 'Enable',
                        cancelButtonTitle: 'Dismiss',
                    })
                        .then(async ({ value }) => {
                            if (value) {
                                try {
                                    await biometric.authenticate().then(() => {
                                        biometric.setEnabled(true)
                                    }).catch(() => {
                                        biometric.setEnabled(false)
                                    })
                                } catch (error) {
                                    await biometric.setEnabled(false)
                                    snackbar({
                                        message: `Unable to enable ${ biometryType }`,
                                        type: 'error',
                                    })
                                }
                            }
                        })
                }

                // Disable animation when mocking
                const animation = !mock.mocking() ? launchAnimation : null
                router.replace({ name: 'dashboard' }, animation)
            })
            .catch((e) => {
                if (authHelpers.isAuthenticationException(e)) {
                    authHelpers.handleAuthenticationException(
                        e.response.data,
                        router,
                        () => {
                            // Try loading the dashboard again
                            return loadDashboard()
                        },
                        null,
                    )
                    return
                }
                throw e
            })
    }

    const loadApp = () => {
        CapacitorUpdater.notifyAppReady()

        appStore.markLaunched()

        SentryVue.setTag('app_id', appStore.id)
        SentryVue.setTag('js_version', appStore.appVersion)

        analytics.setUserProperty({
            name: 'appName',
            value: appStore.info.name,
        })
        analytics.setUserProperty({
            name: 'appId',
            value: appStore.info.id,
        })
        analytics.setUserProperty({
            name: 'appVersion',
            value: appStore.info.version,
        })

        addMockDataListeners()

        return loadDashboard()
    }

    const isNewerAppVersion = (version) => {
        return semver.valid(version)
            && semver.valid(appVersion.value)
            && semver.gt(version, appVersion.value)
    }

    const downloadLatestAppVersion = async () => {
        if (!doesHotCodeUpdates.value) {
            return
        }
        console.log('checking for new version')
        const data = await appStore.checkVersion()

        if (isNewerAppVersion(data.version)) {
            console.log('downloading new version', data.version)
            status.value = 'Updating app...'
            const version = await CapacitorUpdater.download(data)
            await CapacitorUpdater.set(version)
        }

        status.value = null
    }

    const checkAppVersion = async () => {
        if (!doesHotCodeUpdates.value) {
            return
        }
        console.log('check app version', hotReloadDisabled)
        const data = await appStore.checkVersion()
        if (isNewerAppVersion(data.version)) {
            newAppVersion = await CapacitorUpdater.download(data)
        }
    }

    const checkStoreVersion = () => {
        if (!Capacitor.isNativePlatform()) {
            return
        }
        if (checkedStoreAt.value && (checkedStoreAt.value - Date.now()) < 300000) {
            // Don't check for updates if already checked within 5 minutes
            return
        }
        checkedStoreAt.value = Date.now()
        AppUpdate.getAppUpdateInfo()
            .then((result) => {
                if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
                    return
                }
                if (deviceStore.device.platform === 'android') {
                    AppUpdate.startFlexibleUpdate()
                } else {
                    Dialog.confirm({
                        title: 'Update available',
                        message: 'To use this app, download the latest version.',
                        okButtonTitle: 'Update',
                        cancelButtonTitle: 'Dismiss',
                    })
                        .then(({ value }) => {
                            if (!value) {
                                return
                            }
                            AppUpdate.openAppStore()
                        })
                }
            })
    }

    const retry = () => {
        appStore.restart()
    }

    const reset = () => {
        appStore.resetStorage()
        appStore.restart()
    }

    const handleBrowserUrlAuthCompletion = (url) => {
        if (!url) {
            return Promise.resolve()
        }

        // Display error messages from the auth store in App Viewer
        authStore.events.on('error', (e) => {
            if (moduleHelpers.isActionException(e)) {
                moduleHelpers.handleActionException(
                    e.response.data,
                )
            }
        })

        authStore.events.on('login', async (payload) => {
            // Set detail in local storage
            await preferences.setGlobalItem({ key: 'url_auth_completed_event', value: JSON.stringify(payload) })

            // Close this window
            try {
                window.close()
            } catch (e) {
                console.log('Error closing window', e)
            }
        })

        return authStore.complete({ url })
            .then(() => {
                showButtons.value = false

                throw new Error('Please close this window and continue on the original app window.')
            })
    }

    // Ensure listeners aren't added before app info has loaded
    // Events like appUrlOpen are retained by capacitor until the listener is registered, we don't
    // need to worry about when it's registered
    const addListeners = () => {
        App.addListener('appUrlOpen', (event) => {
            // Fetch URL after the scheme
            const url = event.url.split('//')[1]
            if (!url) {
                return
            }
            // Handle authentication complete`
            if (startsWith(url, 'auth/complete')) {
                // Fetch the complete URL from the query parameter
                const params = new URLSearchParams(url.split('?')[1])
                const completeUrl = params.get('complete_url')

                // Complete the authentication flow by requesting the complete URL
                authStore.complete({ url: completeUrl })
            }
            // Handle draft mode links
            if (startsWith(url, 'version/')) {
                const versionId = url.split('/')[1]
                appStore.setDraftVersion(versionId)
                    .then(() => {
                        appStore.restart()
                    })
            }
        })

        App.addListener('resume', (event) => {
            notificationsStore.fetch()

            if (!Capacitor.isNativePlatform() || !authStore.registered) {
                return
            }

            // Trigger an update of device details
            authStore.update()
        })

        App.addListener('appStateChange', async (state) => {
            if (state.isActive) {
                checkAppVersion()
                checkStoreVersion()
            }
            if (!state.isActive && isNewerAppVersion(newAppVersion.version)) {
                try {
                    appVersion.value = newAppVersion.version
                    await CapacitorUpdater.set(newAppVersion)
                } catch (err) {
                    console.log(err)
                }
            }
        })

        window.addEventListener('popstate', (e) => {
            // Remove all modules that were on top of the new module
            const module = /^\/view\/([^/]+)/.exec(e.state.current)?.[1]
            if (module) {
                moduleStore.removeModulesUntil(module)
            } else {
                moduleStore.removeAllModules()
            }
        })
    }

    const setApiUrl = async (regionOverride) => {
        const url = Capacitor.isNativePlatform()
            ? await AppIdentity.getEnvironment().then((environment) => environment.apiUrl)
            : import.meta.env.VITE_API_URL

        if (!regionOverride) {
            api.defaults.baseURL = url
            return
        }

        // Sanitise the regionOverride, only support a-z 0-9 .
        const sanitisedRegion = replace(regionOverride, /[^a-z0-9.]/gi, '')

        // Replace the region prefix in the API_URL
        api.defaults.baseURL = replace(import.meta.env.VITE_API_URL, /(?<=\/\/)[a-z0-9]+(?=\.api)/i, sanitisedRegion)
    }

    onBeforeMount(async () => {
        await biometric.updateBiometryInfo()

        try {
            biometricListener.value = biometric.addResumeListener()
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message)
            }
        }
    })

    onMounted(() => {
        const initialSearchParams = new URLSearchParams(appStore.initialUrlSearch)

        // Check if the app was started with an app ID specified in the URL
        const appIdOverride = initialSearchParams.has('app_id')
            ? initialSearchParams.get('app_id')
            : null

        // Check if the app was started with a region specified in the URL
        const regionOverride = initialSearchParams.has('region')
            ? initialSearchParams.get('region')
            : null

        // Check if the app should use mock data
        const mockData = initialSearchParams.has('mock_data') || import.meta.env.VITE_MOCK_DATA

        // Load device before doing anything else
        appStore.init(appIdOverride, mockData, initialSearchParams.get('version'))
            .then(() => setApiUrl(regionOverride))
            .then(() => Promise.all([
                authStore.init(),
                deviceStore.init(),
            ]))
            .then(() => console.log('auth + device stores initialised'))
            .then(() => {
                // If we have already registered trigger an update, but don't wait for it complete
                if (authStore.registered) {
                    authStore.update()
                    return Promise.resolve()
                }

                return authStore.register()
            })
            .then(async () => {
                await versionStore.load()

                console.log('version', toRaw(version.value))

                if (version.value.primary_color) {
                    Colors.appendStylesheet(version.value.primary_color)
                }

                notificationsStore.setNotificationsEnabled(version.value.notifications_enabled)
            })
            .then(() => checkBiometry())
            .then(() => addListeners())
            .then(async () => {
                // If there's an error downloading the latest version continue
                return downloadLatestAppVersion()
                    .catch((e) => {
                        // Report the error to sentry
                        SentryVue.captureException(e)
                        return null
                    })
            })
            .then(() => checkStoreVersion())
            .then(() => handleBrowserUrlAuthCompletion(initialSearchParams.get('url_auth_complete_url')))
            .then(() => checkLegacyStorage())
            .then(() => loadApp())
            .catch((e) => {
                console.log('Error launching app', e)
                error.value = e
            })
    })

    onBeforeUnmount(async () => {
        (await biometricListener.value)?.remove()
        loading.value = false
    })
</script>
